import { BaseEntity } from './shared/base-entity';
import { MediaItem } from './media-item.entity';
import { User } from './user.entity';
import { CursorVirtuals } from './shared/cursor-virtuals';

export enum MessageResourceReferenceResourceType {
  CaseReportResolution = 'caseReportResolution',
  CaseReportCompletion = 'caseReportCompletion',
  OrderResolution = 'orderResolution',
  FollowUp = 'followUp',
  FollowUpReview = 'followUpReview',
}

export interface MessageResourceReference {
  resourceType: MessageResourceReferenceResourceType;
  resourceId: string | null;
}

export enum MessageConversationSide {
  Patient = 'patient',
  Doctor = 'doctor',
}

export interface MessageAttachments {
  link: string | null;
  media: MediaItem[];
  resourceReference: MessageResourceReference | null;
}

export interface MessageView {
  viewedAt: string;
  userId: string;
}

export interface Message extends BaseEntity, CursorVirtuals {
  caseReportId: string;
  text: string | null;
  views: MessageView[];
  author: User;
  conversationSide: MessageConversationSide;
  attachments: MessageAttachments;
  ticketId: string | null;
}
