import React from 'react';
import { useTranslation } from 'next-i18next';
import { Column, Row } from 'react-table';
import Link from 'next/link';
import ReactTableBlock from '../react-table-block/react-table-block.component';
import { Ticket } from '../../model/entities/ticket.entity';
import useTicketsQuery from '../../model/hooks/use-tickets-query';
import { User } from '../../model/entities/user.entity';
import formatDateLocalized, {
  DateFormatterStyle,
} from '../../utils/format-date-localized/format-date-localized';
import { MessageConversationSide } from '../../model/entities/message.entity';
import getByLineFromUser from '../../utils/get-by-line-from-user/get-by-line-from-user';
import getInitialsFromName from '../../utils/get-initials-from-name/get-initials-from-name';

interface AwaitingReplyTicketsListProps {
  user: User;
}

const AwaitingReplyTicketsList: React.FC<AwaitingReplyTicketsListProps> = ({ user }) => {
  const { t, i18n } = useTranslation();

  const { data, isLoading, isFetching } = useTicketsQuery({
    page: 1,
    size: 1000,
    assignees: [user.id],
    needsAttention: true,
  });

  const columns: Column<Ticket>[] = React.useMemo(
    () => [
      {
        id: 'latestMessageDate',
        Header: t('AWAITING_REPLY_TICKETS_LIST.TABLE.COLUMNS.CREATED_AT'),
        width: 200,
        accessor: el =>
          el.latestMessageDate
            ? formatDateLocalized(
                new Date(el.latestMessageDate),
                DateFormatterStyle.Datetime,
                i18n.resolvedLanguage
              )
            : null,
        sortType: 'datetime',
        Cell: ({ value }: { value: string }) => <div className="text-nowrap">{value}</div>,
      },
      {
        id: 'latestMessageAuthor',
        Header: t('AWAITING_REPLY_TICKETS_LIST.TABLE.COLUMNS.AUTHOR').toString(),
        accessor: 'latestMessage',
        width: 250,
        disableSortBy: true,
        Cell: ({ row }: { row: Row<Ticket> }) => {
          const { original } = row;
          const { latestMessage } = original;
          return (
            latestMessage != null && (
              <div className="d-flex flex-nowrap align-items-center gap-2">
                <div
                  className="custom-chat__avatar bg-light"
                  style={{
                    backgroundImage:
                      latestMessage.author.profilePictureUrl != null
                        ? `url(${latestMessage.author.profilePictureUrl})`
                        : 'url(/img/user-avatar-placeholder.svg)',
                  }}
                />
                <div className="font-size-sm" style={{ width: 180 }}>
                  {latestMessage.conversationSide === MessageConversationSide.Doctor
                    ? getByLineFromUser(latestMessage.author)
                    : getInitialsFromName(latestMessage.author.fullName)}
                </div>
              </div>
            )
          );
        },
      },
      {
        id: 'latestMessage',
        Header: t('AWAITING_REPLY_TICKETS_LIST.TABLE.COLUMNS.TEXT').toString(),
        accessor: 'latestMessage',
        disableSortBy: true,
        Cell: ({ row }: { row: Row<Ticket> }) => {
          const { original } = row;
          const { latestMessage } = original;
          return latestMessage != null ? (
            <div>
              <div className="font-size-sm">{latestMessage.text}</div>
            </div>
          ) : (
            <span>—</span>
          );
        },
      },
      {
        id: 'manageButtons',
        Header: '',
        disableSortBy: true,
        width: 200,
        Cell: ({ row }: { row: Row<Ticket> }) => (
          <div className="ms-2">
            <Link href={`/tickets/${row.original.id}`}>
              <a>{t('AWAITING_REPLY_TICKETS_LIST.TABLE.COLUMNS.MANAGE_BUTTONS.VIEW_TICKET')}</a>
            </Link>
          </div>
        ),
      },
    ],
    [t, i18n]
  );

  const getRowId = React.useCallback((row: Ticket) => row.id.toString(), []);

  return (
    <ReactTableBlock<Ticket>
      columns={columns}
      getRowId={getRowId}
      data={data?.data || []}
      emptyText={t('AWAITING_REPLY_TICKETS_LIST.EMPTY.TEXT')}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  );
};

export default AwaitingReplyTicketsList;
