import BaseService from './base-service';
import { PaginatedResponse } from '../../types/paginated-response';
import { Ticket, TicketStatus } from '../../entities/ticket.entity';
import { RequestBodyType } from '../../types/request-body-type';
import { PaginatedQuery } from '../../types/paginated-query';
import { SortableQuery } from '../../types/sortable-query';

export enum TicketsListSortBy {
  CreatedAt = 'createdAt',
  LatestMessageDate = 'latestMessageDate',
  NeedsAttention = 'needsAttention',
}

export interface TicketsListQuery extends PaginatedQuery, SortableQuery<TicketsListSortBy> {
  caseReportId?: string;
  closed?: boolean;
  closedBy?: string;
  assigned?: boolean;
  needsAttention?: boolean;
  assignees?: string[];
  createdBy?: string;
  status?: TicketStatus;
  dateFrom?: string;
  dateTo?: string;
}

export interface CloseTicketParams {
  ticketId: string;
  syncToEmr: boolean;
  evaluationAndAction?: string;
}

export interface UpdateTicketParams {
  ticketId: string;
  data: RequestBodyType<Ticket>;
}

class TicketsService extends BaseService {
  getTickets(params: TicketsListQuery): Promise<PaginatedResponse<Ticket>> {
    return this._apiClient.get('/api/rest/v1/tickets', { params }).then(response => response.data);
  }

  getTicketDetails(id: string): Promise<Ticket> {
    return this._apiClient.get(`/api/rest/v1/tickets/${id}`).then(response => response.data);
  }

  closeTicket(params: CloseTicketParams): Promise<Ticket> {
    const { ticketId, evaluationAndAction, syncToEmr } = params;
    return this._apiClient
      .post(`/api/rest/v1/tickets/${ticketId}/close`, { evaluationAndAction, syncToEmr })
      .then(response => response.data);
  }

  updateTicket(params: UpdateTicketParams): Promise<Ticket> {
    const { ticketId, data } = params;
    return this._apiClient
      .patch(`/api/rest/v1/tickets/${ticketId}`, data)
      .then(response => response.data);
  }
}

export default TicketsService;
