import React from 'react';
import classNames from 'classnames';

export interface CardTab {
  id: string;
  label: string;
  Content: React.FC;
  isDefault?: boolean;
  count?: number;
}

interface CardWithTabsProps {
  tabs: CardTab[];
}

const CardWithTabs: React.FC<CardWithTabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = React.useState(tabs.find(tab => tab.isDefault)?.id);

  const TabContent = tabs.find(tab => tab.id === activeTab)?.Content;

  return (
    <div className="tab">
      <div
        className={classNames('nav', 'nav-tabs', 'custom-nav-tabs', 'custom-nav-tabs--card-layout')}
      >
        {tabs.map(tab => (
          <div
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={classNames(
              'nav-item',
              'custom-nav-tabs__item',
              activeTab === tab.id && 'custom-nav-tabs__item--active'
            )}
          >
            <a className="nav-link">
              {tab.label}
              {tab.count != null && tab.count > 0 && (
                <div className="badge bg-primary ms-2">{tab.count}</div>
              )}
            </a>
          </div>
        ))}
      </div>
      {TabContent && <TabContent />}
    </div>
  );
};

export default CardWithTabs;
