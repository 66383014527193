import { User } from '../../model/entities/user.entity';

const getByLineFromUser = (user: User) => {
  const { preferredName, jobTitle, firstName } = user;

  const name = preferredName || firstName;
  return [name, jobTitle].filter((x): x is string => !!x).join(', ');
};

export default getByLineFromUser;
