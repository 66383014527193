import React from 'react';
import { GetStaticProps, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { Container } from 'react-bootstrap';
import withAuth, { WithAuthProps } from '../utils/with-auth/with-auth';
import Dashboard from '../layouts/dashboard';
import getServerSideTranslations from '../utils/get-server-side-translations/get-server-side-translations';
import PageMeta from '../components/page-meta/page-meta.component';
import DashboardStats from '../components/dashboards-stats/dashboard-stats.component';
import Gate from '../components/gate/gate.component';
import { Action, ProtectedResource } from '../model/entities/role.entity';
import LastOpenedCaseReportsList from '../components/last-opened-case-reports-list/last-opened-case-reports-list.component';
import AwaitingReplyTicketsList from '../components/awaiting-reply-tickets-list/awaiting-reply-tickets-list.component';
import CardWithTabs, { CardTab } from '../components/card-with-tabs/card-with-tabs';
import useCheckAccess from '../hooks/use-check-access/use-check-access';

const Home: NextPage<WithAuthProps> = ({ user }) => {
  const { t } = useTranslation();
  const checkAccess = useCheckAccess();

  const cardTabs: CardTab[] = [];

  if (checkAccess([ProtectedResource.Ticket, Action.List])) {
    cardTabs.push({
      id: 'awaitingReplyTickets',
      label: t('HOME.TABS.AWAITING_REPLY_TICKETS'),
      Content: () => <AwaitingReplyTicketsList user={user} />,
      isDefault: true,
    });
  }

  if (checkAccess([ProtectedResource.CaseReport, Action.List])) {
    cardTabs.push({
      id: 'lastOpenedCaseReports',
      label: t('HOME.TABS.LAST_OPENED_CASE_REPORTS'),
      Content: LastOpenedCaseReportsList,
    });
  }

  return (
    <Container fluid className="p-0">
      <PageMeta title={t('HOME.META.TITLE')} />
      <h1 className="h3 mb-3">{t('HOME.PAGE_TITLE')}</h1>
      <p>{t('HOME.DESCRIPTION')}</p>
      <Gate config={[ProtectedResource.Stats, Action.Read]}>
        <div style={{ height: 40 }} />
        <DashboardStats />
      </Gate>
      {cardTabs.length > 0 && <CardWithTabs tabs={cardTabs} />}
    </Container>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await getServerSideTranslations(locale, true)),
    },
  };
};

// @ts-ignore
Home.getLayout = (page: React.ReactElement) => <Dashboard>{page}</Dashboard>;

export default withAuth(Home, []);
