import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import useApiClient from './use-api-client';
import { PaginatedResponse } from '../types/paginated-response';
import { Ticket } from '../entities/ticket.entity';
import TicketsService, { TicketsListQuery } from '../api/services/tickets.service';

export const TICKETS_QUERY_KEY = 'tickets';

type PaginatedTickets = PaginatedResponse<Ticket>;

const useTicketsQuery = (
  params: TicketsListQuery,
  options?: UseQueryOptions<PaginatedTickets>
): UseQueryResult<PaginatedTickets> => {
  const apiClient = useApiClient();

  return useQuery<PaginatedTickets>(
    [TICKETS_QUERY_KEY, params],
    () => new TicketsService(apiClient).getTickets(params),
    options
  );
};

export default useTicketsQuery;
